/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import CursorHoverable from "~components/CursorHoverable";
import ReachOutCTA from "~components/ReachOutCTA";
import SEO from "~components/SEO";
import { getRandomIntByRange, shuffleArray } from "~utils/helpers";
import { parseProducts } from "~utils/shopify";

const ArtistsPage = ({ data, location }) => {
  const { darkMode, shuffledArtists, setShuffledArtists } = useContext(
    AppContext
  );
  const { device } = useContext(DocumentContext);

  const { frontmatter } = data.markdownRemark;

  //

  useEffect(() => {
    if (!shuffledArtists) {
      const artists = parseProducts(data);

      if (
        !artists[0]?.frontmatter?.image?.childImageSharp?.fluid ||
        !artists[0]?.frontmatter?.hoverImage?.childImageSharp?.fluid
      ) {
        return;
      }

      const newlyShuffledArtists = shuffleArray(
        JSON.parse(JSON.stringify(artists))
      );

      setShuffledArtists(newlyShuffledArtists);
    }
  }, []);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout
        className={`artists-page w-full relative transition-background bg-${
          darkMode ? `black` : `white`
        } text-${darkMode ? `white` : `black`}`}
      >
        <div className="artists-page__list grid pt-v12 xs:pt-v6 pb-v4">
          {shuffledArtists?.[0] && (
            <ul className="grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1 mt-v3 xs:mb-v8">
              {shuffledArtists.map((archiveItem, archiveItemIndex) => {
                let mediaJSX;

                if (device && device === `desktop`) {
                  if (archiveItem?.frontmatter?.image?.childImageSharp?.fluid) {
                    mediaJSX = (
                      <Img
                        className="w-full h-full relative block object-cover"
                        fluid={
                          archiveItem.frontmatter.image.childImageSharp.fluid
                        }
                        alt={archiveItem.frontmatter.imageAlt}
                      />
                    );
                  } else if (archiveItem?.frontmatter?.image?.publicURL) {
                    mediaJSX = (
                      <img
                        className="w-full h-full relative block object-cover"
                        src={archiveItem.frontmatter.image.publicURL}
                        alt={archiveItem.frontmatter.imageAlt}
                      />
                    );
                  }
                }

                if (!archiveItem.rightMargin) {
                  archiveItem.rightMargin = getRandomIntByRange(-5, 25);
                }

                return (
                  <li
                    className="artists-page__archive-item animation-appear-down w-full relative mb-v2"
                    style={{ animationDelay: `${archiveItemIndex * 100}ms` }}
                  >
                    <CursorHoverable>
                      <Link to={archiveItem.slug}>
                        <div className="w-full relative xs:pt-v4 xs:pb-v4 flex xs:items-center xs:justify-between">
                          <h3
                            className={`hover-underline ${
                              device === `desktop` ? `f3` : `f5`
                            }`}
                          >
                            {archiveItem.frontmatter.artist}
                          </h3>
                        </div>

                        <div
                          className="artists-page__archive-item__image desktop-only w-1/3 absolute pointer-events-none"
                          style={{ right: `${archiveItem.rightMargin}%` }}
                        >
                          <div className="artists-page__archive-item__text w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center">
                            <Link to={archiveItem.slug}>
                              <h3 className="f5 text-white">Shop Print</h3>
                            </Link>
                          </div>

                          <figure className="w-full h-full relative gpu overflow-hidden">
                            {mediaJSX && mediaJSX}
                          </figure>
                        </div>
                      </Link>
                    </CursorHoverable>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <ReachOutCTA />
      </Layout>

      <Footer />
    </>
  );
};

export default ArtistsPage;

export const query = graphql`
  query ArtistsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            shopifyHandle
            title
            artist
            image {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            hoverImage {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            sku
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
              title
            }
          }
        }
      }
    }
  }
`;
